<template>
  <div>
    <div>
      <div :class="{ 'bg-white pt-8 md:pt-12 -mb-8 md:-mb-10': performances }">
        <div class="myContainer">
          <div v-if="performances" class="grid gap-6 lg:grid-cols-11">
            <!-- Player container -->
            <div class="lg:col-span-7">
              <PlayerReplayHomePage
                  :performances="performances"
              />
            </div>

            <PlayerReplayChapter
              class="relative gradient-background pt-4 pb-6 px-6 lg:col-span-4"
              :performance="performance"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PlayerReplayHomePage from "@/components/player/PlayerReplayHomePage"
// import PlayerLiveChapter from "@/components/player/PlayerLiveChapter"
// import LiveInforModal from "@/components/LiveInforModal"
// import BannerVote from "@/components/BannerVote";
// import { brahmsWebSocket } from "@/js/brahms_websocket"
import {appAxios} from "@/js/app_axios";
import PlayerReplayChapter from "@/components/player/PlayerReplayChapter.vue";

export default {
  name: "player-performance-home-page-wrapper",
  components: {
    PlayerReplayChapter,
    PlayerReplayHomePage,
    // PlayerLiveChapter,
    // LiveInforModal,
    // BannerVote
  },
  data() {
    return {
      performances: null,
      performance: null
    }
  },
  props: {
  },
  computed: {},
  async mounted() {
    await this.getPerformances()
  },
  methods: {
    async getPerformances() {
      const { data } = await appAxios.get("/en/api/v2/performances/?offset=0&limit=1")
      if (data.performances.length > 0) {
        this.performances = data.performances
        this.performance = this.performances[0]
        // this.$store.commit("set_performanceList", this.performances)
      }
    }
  }
}
</script>

<style lang="scss">
.player-chapter {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
