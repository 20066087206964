<template>
  <div></div>
</template>

<script>
  export default {
    name: "update-store",
    props: {
      transifex: null,
      languageCode: {
        type: String,
        default: "en"
      },
      viewerCountry: {
        type: String,
        default: ""
      },
      viewerCountryName: {
        type: String,
        default: ""
      },
      viewerCity: {
        type: String,
        default: ""
      },
    },
    async beforeCreate() {
      this.$store.commit("set_transifex", this.transifex)
      this.$store.commit("set_languageCode", this.languageCode)
      this.$store.commit("set_viewerCountry", this.viewerCountry)
      this.$store.commit("set_viewerCountryName", this.viewerCountryName)
      this.$store.commit("set_viewerCity", this.viewerCity)
    }
  }
</script>
