<template>
  <div style="position: relative">
    <div
      v-if="currentLive.is_tag_live"
      id="video-tag-live"
      class="video-tag-live"
    >
      <span>Live</span>
    </div>
    <div id="newsletter-overlay" class="newsletter-overlay">
      <PlayerNewsletter/>
    </div>
    <div :id="id"></div>
  </div>
</template>

<script>
import { PlayerUtils, JwplayerViewManager } from "../../js/medici_player"
import PlayerNewsletter from "@/components/player/PlayerNewsletter"

export default {
  name: "player-live",
  components: {
    PlayerNewsletter
  },
  props: {
    currentLive: null,
    isOnEmbed: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      id: "player-brahms",
      jwplayerView: new JwplayerViewManager(),
      playerLoaded: false
    }
  },
  watch: {
    currentLive(newVal, oldVal) {
      if (newVal.stream.id !== oldVal.stream.id) {
        this.loadMovie()
      }
      this.isLiveInforModal(!!this.currentLive.live_update)
    }
  },
  mounted() {
    this.loadJwplayer()
  },
  methods: {
    loadMovie() {
      if (this.currentLive.stream?.url) {
        const additionalSettings = {
          playlist: PlayerUtils.formatPlayListLive(this.currentLive),
          mute: true
        }
        if (!this.playerLoaded) {
          /* Don't load ads 2 times in auto reload player */
          additionalSettings["advertising"] = {
            client: "vast",
            schedule: "/en/vmap/",
            rules: {
              frequency: 0 // Pub only on 1st element of playlist
            }
          }
        }
        let movieGtmData = this.getGtmData()
        let movieAudienceData = this.getAudienceData()
        this.$store.commit("start_player", {
          additionalSettings,
          movieGtmData,
          movieAudienceData
        })
        this.playerLoaded = true
      } else {
        console.error("Stream can not null, please add stream to Live")
      }
    },
    addPlayerOptions() {
      let player = this.$store.state.playerInstance
      this.jwplayerView.addTagLiveOnReady(player)
      if (!this.isOnEmbed) this.jwplayerView.addNewsletterOverlay(player)
    },
    getGtmData() {
      return {
        video_id: this.currentLive.id, // id for live
        video_name: this.currentLive.title,
        video_category: this.currentLive.instrument,
        video_type: "live",
        video_url: this.currentLive.slug
      }
    },
    getAudienceData() {
      let context = {}
      if (this.$store.state.viewerCountry) {
        context.country_code = this.$store.state.viewerCountry
      }
      if (this.$store.state.viewerCity) {
        context.city = this.$store.state.viewerCity
      }
      if (this.$store.state.viewerCountryName) {
        context.country = this.$store.state.viewerCountryName
      }
      return {
        audience: {
          id: this.currentLive.id,
          slug: this.currentLive.slug, // tracking session of Live
          type: this.isOnEmbed ? "embed_live" : "live",
          category: this.currentLive.instrument
        },
        context: context
      }
    },
    isLiveInforModal(isShowing = false) {
      let modal = document.getElementById("modalLiveInfor")
      if (isShowing) modal.style.display = "block"
      else modal.style.display = "none"
    },
    loadJwplayer() {
      if (window.jwplayer) {
        this.$store.commit("set_player", this.id)
        this.loadLive()
      } else {
        let script = document.createElement("script")
        script.onload = () => {
          this.$store.commit("set_player", this.id)
          this.loadLive()
        }
        script.onerror = () => {
          console.error("jwaplyer init error, id: " + this.id)
        }
        script.src = "https://api.medici.tv/proxy-jwplayer/cdnjwplayer/libraries/A3dE0fZq.js"
        document.head.appendChild(script)
      }
    },
    loadLive() {
      this.loadMovie()
      this.addPlayerOptions()
      this.isLiveInforModal(!!this.currentLive.live_update)
    }
  }
}
</script>

<style lang="scss">
.video-tag-live {
  position: absolute;
  top: 15px;
  left: 20px;
  display: none;
  padding: 3px 10px 3px 10px;
  font-size: 14px;
  color: white;
  font-weight: 600;
  z-index: 5;
  border-radius: 2px;

  text-transform: uppercase;
  background-color: red;
}
</style>
