import {MEvent} from "./m_event";
import { PauseAbleTimer } from "./utils"

class StatsCollector extends MEvent {
  constructor(playerInstance) {
    super()
    this.playerInstance = playerInstance
    this.collectedStats = {
      id: "",
      source: "",
      first_frame: 0,
      buffering: 0,
      buffer_len: 0,
      bitrate: {},
      download: {}
    }
    this.downloads = []
    this.last_bitrate = null
    this.timersCollector = []
  }

  startCollect(firstFrame) {
    /* Call in hook firstFrame */
    this.collectedStats.id = ("" + Math.random()).substr(2)
    this.collectedStats.first_frame = firstFrame.loadTime / 1000
    this.collectSource(this.playerInstance)

    const stats_timer_5s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+5", this.collectedStats)
      stats_timer_5s.clearTimer()
    }, 5 * 1000)

    const stats_timer_30s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+30", this.collectedStats)
      stats_timer_30s.clearTimer()
    }, 30 * 1000)

    const stats_timer_120s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+120", this.collectedStats)
      stats_timer_120s.clearTimer()
    }, 120 * 1000)

    const stats_timer_180s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+180", this.collectedStats)
      stats_timer_180s.clearTimer()
    }, 180 * 1000)

    const stats_timer_300s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+300", this.collectedStats)
      stats_timer_300s.clearTimer()
    }, 300 * 1000)

    const stats_timer_900s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+900", this.collectedStats)
      stats_timer_900s.clearTimer()
    }, 900 * 1000)

    const stats_timer_1800s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+1800", this.collectedStats)
      stats_timer_1800s.clearTimer()
    }, 1800 * 1000)

    const stats_timer_2700s = new PauseAbleTimer(() => {
      this.updateStatsBeforeSend()
      this.stats("FF+2700", this.collectedStats)
      stats_timer_2700s.clearTimer()
    }, 2700 * 1000)

    this.timersCollector = [
      stats_timer_5s,
      stats_timer_30s,
      stats_timer_120s,
      stats_timer_180s,
      stats_timer_300s,
      stats_timer_900s,
      stats_timer_1800s,
      stats_timer_2700s
    ]
    this.timersCollector.forEach(function (timer) {
      timer.startTimer()
    })
  }

  collectBitrate(quality) {
    /* Call in hook player.on('visualQuality') and before send event */
    let bitrate = 0
    if (quality) {
      const exact_bitrate = quality.level.bitrate
      bitrate = Math.ceil(exact_bitrate / 1000000) * 1000000
    }
    const now = new Date()
    if (this.last_bitrate) {
      this.collectedStats.bitrate[this.last_bitrate.bitrate] =
        (this.collectedStats.bitrate[this.last_bitrate.bitrate] || 0) +
        (now - this.last_bitrate.date) / 1000
    }
    this.last_bitrate = {
      date: now,
      bitrate: bitrate || (this.last_bitrate ? this.last_bitrate.bitrate : 0)
    }
  }

  collectBuffering(buffer) {
    /* Call in hook player.on('buffer') */
    if (
      buffer.newstate === "buffering" &&
      buffer.oldstate === "playing" &&
      buffer.reason === "stalled"
    )
      this.collectedStats.buffering += 1
  }

  collectBufferLen(playerInstance) {
    this.collectedStats.buffer_len =
      (playerInstance.getDuration() * playerInstance.getBuffer()) / 100 -
      playerInstance.getPosition()
  }

  collectSource(playerInstance) {
    const item = playerInstance.getPlaylistItem(
      playerInstance.getPlaylistIndex()
    )
    if (item) {
      const file = new URL(item.file)
      this.collectedStats.source = {
        origin: file.origin,
        path: file.pathname + file.search
      }
    }
  }

  updateStatsBeforeSend() {
    this.collectBufferLen(this.playerInstance)
    this.collectBitrate()
  }
}

class AudienceCollector extends MEvent {
  constructor(playerInstance, audienceTrackingData = {}) {
    super()
    this.playerInstance = playerInstance
    this.audienceStats = {
      id: ("" + Math.random()).substr(2),
      movie: { ...audienceTrackingData.audience },
      video: {}
    }
    this.audienceContext = audienceTrackingData.context
    this.timersCollector = []
    this.isUSerPause = false
    this.intervalVu1M = ""
  }

  startCollect() {
    /* Call in hook firstFrame */

    const roy_timer = new PauseAbleTimer(() => {
      this.sendAudienceStats("ROY")
      roy_timer.clearTimer()
    }, 180 * 1000)

    this.timersCollector = [roy_timer]

    this.timersCollector.forEach(function (timer) {
      timer.startTimer()
    })
  }

  triggerAudienceSACEM() {
    this.sendAudienceStats("SACEM")
  }

  triggerAudiencePLAY() {
    /* Trigger this when the user start a video */
    this.sendAudienceStats("PLAY")
    this.setTriggerIntervalVU1M()
  }

  triggerAudiencePAUSE() {
    /* Trigger this when the user click pauses the video */
    this.sendAudienceStats("PAUSE")
    this.isUSerPause = true
    this.clearTriggerIntervalVU1M()
  }

  triggerAudienceRESUME() {
    /* Trigger this event when the user play the video (after a user pause) */
    if (this.isUSerPause) {
      /* Flag isUSerPause used to not allow the trigger of resume player after buffering */
      this.sendAudienceStats("RESUME")
      this.isUSerPause = false
      this.setTriggerIntervalVU1M()
    }
  }

  triggerAudienceSTOP() {
    /* Quit page, user choose other item in playlist */
    this.sendAudienceStats("STOP")
  }

  triggerAudienceEND() {
    this.sendAudienceStats("END")
  }

  setTriggerIntervalVU1M() {
    /* Trigger this type of event every 1 mn of the user playing a video (this is not WATCHTIME time but playing time) */
    this.intervalVu1M = setInterval(() => {
      this.sendAudienceStats("VU1M")
    }, 60 * 1000)
  }

  clearTriggerIntervalVU1M() {
    clearInterval(this.intervalVu1M)
  }

  sendAudienceStats(code) {
    this.updateStatsBeforeSend()
    const playListItem = this.playerInstance.getPlaylistItem(
      this.playerInstance.getPlaylistIndex()
    )
    if (!playListItem.isPreroll) {
      this.audience(code, this.audienceStats, this.audienceContext) // Dont track the preroll
    } else if (["SACEM", "ROY"].includes(code)) {
      this.audience(code, this.audienceStats, this.audienceContext) // Track only Sacem and Roy on preroll
    }
  }

  updateStatsBeforeSend() {
    const listQuality = this.playerInstance.getQualityLevels()

    const currentQuality = listQuality[this.playerInstance.getCurrentQuality()]

    this.audienceStats.video.quality = currentQuality?.label

    if (this.audienceStats.video.quality !== "Auto") {
      this.audienceStats.video.bitrate = currentQuality
        ? Math.ceil(currentQuality.bitrate / 1000000) * 1000000
        : null
    }

    this.audienceStats.video.position = parseInt(
      this.playerInstance.getPosition()
    )
  }
}

class PlayerErrorCollector extends MEvent {
  constructor(playerInstance) {
    super()
    this.playerInstance = playerInstance
  }

  startCollect() {
    const self = this
    const _uncycle = (obj) => {
      let cache = []
      const res = JSON.stringify(obj, function (key, value) {
        if (typeof value === "object" && value !== null) {
          if (cache.includes(value)) {
            // Duplicate reference found, discard key
            return
          }
          // Store value in our collection
          cache.push(value)
        }
        return value
      })
      cache = null // Enable garbage collection
      return JSON.parse(res)
    }

    const collectError = (code, message, cause) => {
      const detail = {
        source: self.currentSource()
      }

      this.error(code, message, _uncycle(cause), detail)
    }

    this.playerInstance.on("setupError", function (error) {
      collectError("JWPLAYER_SETUPERROR", error.message, error)
    })

    this.playerInstance.on("error", function (error) {
      collectError("JWPLAYER_ERROR", error.message, error)
    })

    this.playerInstance.on("playAttemptFailed", function (error) {
      collectError("JWPLAYER_PLAYFAILED", error.playReason, error)
    })

    this.playerInstance.on("warning", function (warning) {
      collectError("JWPLAYER_WARNING", warning.message, warning)
    })
  }

  currentSource() {
    const item = this.playerInstance.getPlaylistItem(
      this.playerInstance.getPlaylistIndex()
    )
    if (item) {
      const file = new URL(item.file)
      return {
        origin: file.origin,
        path: file.pathname + file.search
      }
    }
    return {}
  }
}

export { StatsCollector, AudienceCollector, PlayerErrorCollector }
